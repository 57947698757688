/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./Home.scss";
import { Sidebar } from "../../components/Layout/Header/Sidebar";
import { headerLinks } from "../../components/Layout/Header/headerLinks";
import { Showcase } from "../../components/Showcase";
import { Proccess, Texts } from "../../components/Steps";
import { Accardion } from "../../components/Accardion";
import { ResultCards } from "../../components/ResultCards";
import { CollapseProps } from "rc-collapse";
import { Services } from "../../components/Services";

import { LocationCard } from "../../components/LocationCard/LocationCard";
import { useLocation } from "react-router-dom";
import { CustomForm } from "../../components/Steps/Proccess/form";
import { phone } from "../../utils/configs";

const texts: TextProps[] = [
  {
    colorBox: "#d1905f",
    description:
      "When you research to find the best auto transport company for you, never settle for less. If an auto shipping company doesn’t strive to provide 100% customer satisfaction, they’re not worth your time. Apollo Express Group, our customer-focused approach is designed to prioritize you and your vehicle at every turn. We know you’re busy. We’re here to help.",
    title: `Find a car transport company that puts you and your schedule first.`,
  },
  {
    colorBox: `#93c264`,
    title: `Provide your vehicle transport company with key information about your car shipment.
    `,
    description: `A reputable auto transportation company will only require the most pertinent information about your order to book a shipment. This includes the zip codes of your pickup and delivery locations, the make and model of your car, and special considerations such as enclosed car hauling service.

    `,
  },
  {
    colorBox: "#5fac9f",
    title: `Book online or call your auto transport company directly.`,
    description: `Apollo Express Group has streamlined the online booking process with our state-of-the-art instant vehicle shipping cost calculator. In seconds, you can choose the right auto shipping quote for you and book a shipment that works with your schedule. If you have questions or want to book your shipment with one of our experts, feel free to contact us anytime at ${phone}. We’ll be happy to assist.`,
  },
];

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const getItems: (panelStyle: any) => CollapseProps["items"] = (panelStyle) => [
  {
    key: "1",
    label: <p style={{ color: "white" }}>Why work with Apollo Express Group?</p>,
    children: (
      <div>
        <p style={{ color: "white" }}>
          Apollo Express Group provides the most satisfactory shipment service by
          providing our customers with honest, reliable and competitive service.
        </p>
        <p style={{ color: "white" }}>
          Professional service and respect to our customer are our main
          priorities, we accommodate all shipping needs and guarantee service
          satisfaction.
        </p>
      </div>
    ),
    style: panelStyle,
  },
  {
    key: "2",
    label: (
      <p style={{ color: "white" }}>How can I get a car shipping quote?</p>
    ),
    children: (
      <p style={{ color: "white" }}>
        When you’re ready to transport your car, you can fill out our automated
        quote and booking form. You will fill out basic information about your
        needs and where it’s being shipped to and from. We’ll then use that
        information to provide a quote, and you may proceed to book your order
        online.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "3",
    label: <p style={{ color: "white" }}>Does the quote include insurance?</p>,
    children: (
      <p style={{ color: "white" }}>
        Yes, the price you receive is the final price you will pay. Insurance is
        included in all nationwide transportation among US states, and there are
        no hidden fees. For international orders, the agent will inform you
        about insurance cost.
      </p>
    ),
    style: panelStyle,
  },
  {
    key: "4",
    label: <p style={{ color: "white" }}>Reliable Company?</p>,
    children: (
      <div>
        <p style={{ color: "white" }}>
          The best and easiest way to choose a company to ship is by checking
          their status on the FMCSA website. Also see what kind of reviews the
          company has and how they are approaching your estimate process .
        </p>
        <p style={{ color: "white" }}>
          We recommend you to schedule your car shipping as soon as possible to
          insure that your vehicle is shipped with the time frame which best
          meets your preference.
        </p>
      </div>
    ),
    style: panelStyle,
  },
];

const quote: QuoteData[] = [
  {
    title: "title",
    description:
      "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam explicabo impedit laudantium quod! Magni aliquid eum dignissimos nesciunt laboriosam, aperiam iusto quibusdam possimus molestias debitis aliquam voluptatum in magnam consequuntur, qui officia saepe voluptates! Ullam tempore quasi consequatur sapiente animi fuga atque ratione ipsa nostrum, recusandae quidem laudantium, nulla maiores.",
    companyImage: `https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png`,
    rating: 5,
  },
  {
    title: "title",
    description:
      "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam explicabo impedit laudantium quod! Magni aliquid eum dignissimos nesciunt laboriosam, aperiam iusto quibusdam possimus molestias debitis aliquam voluptatum in magnam consequuntur, qui officia saepe voluptates! Ullam tempore quasi consequatur sapiente animi fuga atque ratione ipsa nostrum, recusandae quidem laudantium, nulla maiores.",
    companyImage: `https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png`,
    rating: 5,
  },
  {
    title: "title",
    description:
      "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam explicabo impedit laudantium quod! Magni aliquid eum dignissimos nesciunt laboriosam, aperiam iusto quibusdam possimus molestias debitis aliquam voluptatum in magnam consequuntur, qui officia saepe voluptates! Ullam tempore quasi consequatur sapiente animi fuga atque ratione ipsa nostrum, recusandae quidem laudantium, nulla maiores.",
    companyImage: `https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png`,
    rating: 5,
  },
  {
    title: "title",
    description:
      "        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam explicabo impedit laudantium quod! Magni aliquid eum dignissimos nesciunt laboriosam, aperiam iusto quibusdam possimus molestias debitis aliquam voluptatum in magnam consequuntur, qui officia saepe voluptates! Ullam tempore quasi consequatur sapiente animi fuga atque ratione ipsa nostrum, recusandae quidem laudantium, nulla maiores.",
    companyImage: `https://kgo.googleusercontent.com/profile_vrt_raw_bytes_1587515358_10512.png`,
    rating: 5,
  },
];

export const Home: React.FC = () => {
  const location = useLocation();
  const panelStyle: React.CSSProperties = {
    marginBottom: 10,
    background: "var(--main-color)",
    borderRadius: "10px",
    border: "none",
  };

  // let arr: { label: string | number; value: string | number }[] = [];
  // for (let i = 1900; i <= 2023; i++) {
  //   arr.push({ label: i, value: i });
  // }

  return (
    <div>
      {location.pathname === "/" ? <Sidebar data={headerLinks} /> : null}
      <div id="home">
        <Showcase />
      </div>
      <div className="container">
        <ResultCards
          data={[
            {
              description: `Clients served`,
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-user-square-rounded"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 13a3 3 0 1 0 0 -6a3 3 0 0 0 0 6z"></path>
                  <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
                  <path d="M6 20.05v-.05a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v.05"></path>
                </svg>
              ),
              title: 5000,
            },
            {
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-truck"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                  <path d="M5 17h-2v-11a1 1 0 0 1 1 -1h9v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
                </svg>
              ),
              title: 5700,
              description: `Vehicles shipped`,
            },
            // {
            //   title: 8000,
            //   description: `Online reviews`,
            //   icon: (
            //     <svg
            //       xmlns="http://www.w3.org/2000/svg"
            //       className="icon icon-tabler icon-tabler-star"
            //       width="24"
            //       height="24"
            //       viewBox="0 0 24 24"
            //       stroke-width="2"
            //       stroke="currentColor"
            //       fill="none"
            //       stroke-linecap="round"
            //       stroke-linejoin="round"
            //     >
            //       <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            //       <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
            //     </svg>
            //   ),
            // },
            {
              title: 4,
              description: `Years in operation`,
              icon: (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-hourglass-empty"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"></path>
                  <path d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"></path>
                </svg>
              ),
            },
          ]}
        />
      </div>
      <div id="steps">
        <div className="container">
          <Texts
            data={texts}
            title="3 Steps to Book with an Auto Transport Company"
          />
        </div>
        {/* <Proccess /> */}
      </div>
      <div id="services">
        <Services />
        <br />
        <br />
      </div>
      <div id="faq">
        <div className="container">
          <Accardion data={getItems(panelStyle)} />
        </div>
      </div>
      <div id="quotes">
        <div className="container">{/* <Quotes data={quote} /> */}</div>
      </div>
      <div id="about-us">
        <div className="container">
          <div className="about-us__content">
            <h2 className="about-us__content__title">About us</h2>

            <p className="about-us__content__description">
              Apollo Express Group LLC focuses on providing reliable transportation
              services to any type of vehicle, cars, motorcycles, RVs, trailers
              to any states inside the USA and other foreign countries as well.
            </p>

            <p className="about-us__content__description">
              Our main goal is to provide quality service for our customers with
              good customer service. We take care of the most cost effective
              service as possible.
            </p>

            <p className="about-us__content__description">
              Our experienced logistics team are the ones who make the
              difference. Each customer has a dedicated agent to handle their
              vehicles. They understand your needs, and strive to exceed your
              expectations.
            </p>

            <p className="about-us__content__description">
              Join the thousands of customer. dealers, auctions, and event
              organizers who trust our service to move their vehicles.
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "var(--main-color)",
        }}
      >
        <div className="container">
          <CustomForm />
        </div>
      </div>
      <div id="contacts">
        <div className="container">
          <div className="contact__location-card contact__item-1 contact__item">
            <LocationCard />
          </div>
          <div className="contact__location-map contact__item-2 contact__item">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1152.9098049765687!2d-84.50989177539138!3d39.101314370378354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8841b15a53273d19%3A0x5a66f812557e1f39!2s201%20E%205th%20St%201900%201302%2C%20Cincinnati%2C%20OH%2045202!5e0!3m2!1sru!2sus!4v1701782517018!5m2!1sru!2sus"
              width="100%"
              height="100%"
              // style="border:0;"
              // allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
