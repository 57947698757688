import classes from "./Logo.module.scss";
import logo_black from "../../images/logo_black.png";
import logo_blue from "../../images/logo_blue.png";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

type Props = {
  color?: "black" | "white" | "blue";
  initalSize?: string;
};

export const Logo: React.FC<Props> = ({ color = "black", initalSize }) => {
  const logos = {
    white: logo,
    black: logo_black,
    blue: logo_blue,
  };
  return (
    <div className={classes.logo}>
      {/* <Link to="/">
        <img
          src={logos[color]}
          alt="logo"
          className={classes.logo__img}
          style={{ width: initalSize }}
        />
      </Link> */}
    </div>
  );
};
