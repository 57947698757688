import React from "react";
import classes from "./Services.module.scss";
import ServiceCard from "./ServiceCard/ServiceCard";
import classNames from "classnames";
import ServiceCardAni from "./ServiceCardAnimation/ServiceCardAni";

import nationwide from "../../data/ani_nationwide.json";
import insurance from "../../data/ani_car-insurance.json";
import container from "../../data/ani_container.json";

import sea_transportation from "../../images/services/sea-transport.png";
import vehicle from "../../images/services/vehicle-transport.png";
import heavy from "../../images/services/heavy-equip.png";

type Props = {
  type?: "animation" | "no-animation";
};

const services_simple = [
  {
    title: "Sea Transportation",
    description:
      "Apollo Express Group’s mission is to offer the best way of transportation for each consumer depending on individual requirements. Apollo Express Group offers consolidated cargo, full container carriage and Roll on Roll off service.",
    img: sea_transportation,
  },
  {
    title: "Vehicle transport",
    description:
      "Apollo Express Group transports all type of vehicles inside the USA and overseas , including heavy trucks, sedan, SUVs , regular trucks. Each cargo is similarly significant and valued with your preference of timing.",
    img: vehicle,
  },
  {
    title: "Nationwide Hauling",
    description:
      "Apollo Express Group offers trustworthy road car transport services in teamwork with our strong companion driver’s network in the USA. Our scheduled groupage pick up and drop off distribution network is widespread. All transports are accomplished according to contracts made with our customers.",
    img: heavy,
  },
];

const WithAimations = [
  {
    title: "Nationwide Hauling",
    description:
      "Apollo Express Group offers trustworthy road car transport services in teamwork with our strong companion driver’s network in the USA. Our scheduled groupage pick up and drop off distribution network is widespread. All transports are accomplished according to contracts made with our customers.",
    animation: nationwide,
  },
  {
    title: "Car Insurance",
    description:
      "Protecting all vehicles with low-priced insurance is vital. Insurance is always optional in our service so that you tell us whether you need one or not. Apollo Express Group recommends their customers to take insurance in order to avoid losses incurring in transit. We offer Full Cover Insurance against Loss or Damage.",
    animation: insurance,
  },
  {
    title: "Container",
    description:
      "Apollo Express Group offers consolidated and full container shipping, in which customers are able to ship overseas their vehicle and their personal belongings together. We also offer private containers for your own vehicle which is size of 20 foot and allow you to ship your valuable things inside it.",
    animation: container,
  },
];

export default function Services({ type }: Props) {
  return (
    <div className={classNames(classes.services, "container")}>
      <div className={classNames(classes.services__simple)}>
        {services_simple.map((i, index) => (
          <ServiceCard {...i} key={index + 1} />
        ))}
      </div>
      <div className={classNames(classes.services__ani)}>
        {WithAimations.map((i, index) => (
          <ServiceCardAni
            {...i}
            key={index + 1}
            text_side={(index + 1) % 2 ? "right" : "left"}
          />
        ))}
      </div>
    </div>
  );
}
