import classes from "./Showcase.module.scss";
import ShowcaseBackground from "../../images/showcase.png";
import Classnames from "classnames";
import classNames from "classnames";
import { CustomForm } from "../CustomForm";

const data = require("../../data/countries.json");

export default function Showcase() {
  return (
    <div className={Classnames(classes.showcase)}>
      <div
        className={classes.background}
        style={{
          backgroundImage: `url(${ShowcaseBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundAttachment: "fixed",
        }}
      ></div>
      <div className={classNames(classes.container, "container")}>
        <div className={classes.showcase__text}>
          <div>
            <h1
              style={{
                textTransform: "uppercase",
              }}
            >
              Your Shipment is our priority
            </h1>
            <p>
            Apollo Express Group is always ready to help you to ship any vehicle, any
              time almost anywhere. We ensure a smooth, hassle-free experience
              with the most reasonable pricing.
            </p>
            {/* <b>Choice is yours where to ship</b> */}
          </div>
        </div>
        <div className={classes.showcase__form}>
          <CustomForm buttonExist={true} countriesData={data?.data} />
        </div>
      </div>
    </div>
  );
}
